import { AppRoutes } from "Routes";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { AppProvider } from "components/AppContext";

function App() {
  useEffect(() => {
    window.addEventListener("resize", () => {
      const rootElement = document.documentElement;
      rootElement.style.setProperty(
        "--screen-height",
        `${window.innerHeight}px`
      );
    });
  }, []);

  return (
    <React.StrictMode>
      <AppProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AppProvider>
    </React.StrictMode>
  );
}

export default App;
