import common from 'locale/vi/common';
import login from 'locale/vi/login';
import menu from 'locale/vi/menu';

const vi = {
  common,
  login,
  menu,
}

export default vi;
