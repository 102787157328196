import { Stack, Theme, Typography, useTheme } from "@mui/material";
import { ROUTES } from "helpers/routes";
import { Link } from "react-router-dom";

const AppLogo: React.FC<{ collapsed?: boolean }> = ({ collapsed = false }) => {
  const theme: Theme = useTheme();

  return (
    <Link
      to={ROUTES.HOME}
      style={{
        textDecoration: "none",
        paddingLeft: theme.spacing(2),
        color: theme.palette.common.white,
      }}
    >
      <Stack alignItems="center">
        <img
          src="/logo.png"
          alt="logo"
          width={150}
          height="auto"
          style={{ marginBottom: 8 }}
        />
        <Typography
          noWrap
          fontWeight={theme.typography.fontWeightBold}
          fontSize={14}
          style={{ margin: 0, userSelect: "none" }}
        >
          Ho Chi Minh City
        </Typography>
      </Stack>
    </Link>
  );
};

export default AppLogo;
