// File: storageUtils.ts

// Define a constant for the access token key to avoid hardcoding the same string
export const ACCESS_TOKEN_KEY = 'accessToken';

const storageUtils = {
  // Save a value to local storage
  saveItem: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },

  // Get a value from local storage
  getItem: (key: string) => {
    return localStorage.getItem(key);
  },

  // Get the access token from local storage
  getAccessToken: () => {
    return storageUtils.getItem(ACCESS_TOKEN_KEY);
  },

  // Save the access token to local storage
  saveAccessToken: (accessToken: string) => {
    storageUtils.saveItem(ACCESS_TOKEN_KEY, accessToken);
  },

  removeToken: () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }
}

export default storageUtils;