const menu = {
  listProjects: "List Projects",
  projectOverview: "Overview",
  projectWorkingList: "Working List",
  projectTimeline: "Timeline",
  projectReport: "Report",
  projectKanban: "Kanban",
  projectSites: "Sites",
  nftManagement: "NFT Management",
  checkpointList: "Checkpoint List",
  nftList: "NFT List",
};

export default menu;
