const login = {
  login: "Đăng nhập",
  email: "Email",
  emailRequired: "Vui lòng nhập email",
  emailFormat: "Email chưa đúng định dạng",
  password: "Mật khẩu",
  passwordRequired: "Vui lòng nhập mật khẩu",
  infoErrorMessage: "Sai email hoặc mật khẩu",
  logout: "Đăng xuất",
};

export default login;
