import { API } from "services/enum/api";
import { fetchData } from "services/fetcher";
import { ICompany } from "services/types/company";

const serviceGetAllCompanies = async (): Promise<IResponseType<ICompany>> => {
  return fetchData(API.COMPANY_GET_ALL);
};

const serviceGetCompanyBySlug = async (slug: string): Promise<IResponseType<ICompany>> => {
  return fetchData(`${API.COMPANY_GET_ONE}/${slug}`);
};

export {
  serviceGetAllCompanies,
  serviceGetCompanyBySlug,
};
