import { serviceGetMe } from "services/api/user";
import { API } from "services/enum/api";
import useSWR, { SWRResponse } from "swr";
import { useCallback, useEffect } from "react";

export function useMe(): SWRResponse<IResponseType<User>, any, any> {
  const { data, isLoading, error, mutate, isValidating } = useSWR<IResponseType<User>, any, any>(API.ME, serviceGetMe);
  // const intervalTime = 300000; // 5 minutes
  const intervalTime = 60000; // 1 minute

  useEffect(() => {
    // Assuming `data` has a `status` property you can check
    if (data && data.status !== "OK" && window.location.pathname !== '/checkin') {
      window.location.href = '/checkin';
    }
  }, [data]);

  useEffect(() => {
    // Set up an interval to revalidate the session every 5 minutes
    const interval = setInterval(() => {
      mutate(); // This will re-fetch the data, using the `serviceGetMe` function
    }, intervalTime);

    // Clean up the interval when the component unmounts or the data changes
    return () => clearInterval(interval);
  }, [mutate]);

  return { data, isLoading, error, mutate, isValidating };
};