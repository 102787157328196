import MuiGrey from '@mui/material/colors/grey';

const purple = {
  50: '#F0ECFB',
  100: '#d6caf2',
  200: '#bba6eb',
  300: '#a081e3',
  400: '#8964DC',
  500: '#7449d5',
  600: '#6a43ce',
  700: '#5c3bc5',
  800: '#4e34bd',
  900: '#3929af',
};

const yellow = {
  50: '#fffde7',
  100: '#fff9c4',
  200: '#fff59d',
  300: '#fef075',
  400: '#fceb55',
  500: '#ffeb3b',
  600: '#fdd835',
  700: '#fbc02d',
  800: '#f9a825',
  900: '#f57f16',
};

const green = {
  50: '#e6f6ed',
  100: '#c2e7d4',
  200: '#9bd8b8',
  300: '#70ca9d',
  400: '#4dbe88',
  500: '#1ab373',
  600: '#13a468',
  700: '#07915b',
  800: '#00804f',
  900: '#006139'
};

const blue = {
  50: '#e2f1ff',
  100: '#b9dcff',
  200: '#8bc6ff',
  300: '#59b0ff',
  400: '#2f9fff',
  500: '#008eff',
  600: '#0380f2',
  700: '#0e6ede',
  800: '#125ccc',
  900: '#153dad'
};

const orange = {
  50: '#fff3e1',
  100: '#ffe0b4',
  200: '#ffcc83',
  300: '#ffb652',
  400: '#ffa630',
  500: '#fe9817',
  600: '#fa8c16',
  700: '#f47c14',
  800: '#ed6d13',
  900: '#e35311',
};

const red = {
  50: '#ffebee',
  100: '#ffccd2',
  200: '#f99798',
  300: '#f26d70',
  400: '#fe474a',
  500: '#ff2f2c',
  600: '#f5222d',
  700: '#e31127',
  800: '#d6001f',
  900: '#c8000f',
};

const grey = {
  ...MuiGrey,
  50: '#e9ebf0',
  100: '#c7cddb',
  200: '#a4adc3',
  300: '#818da9',
  400: '#677498',
  500: '#4e5c89',
  600: '#475480',
  700: '#3f4a74',
  800: '#384167',
  900: '#2c314e',
  1900: '#757F9F',
};

const coral = {
  50: '#fbebe8',
  100: '#ffd1be',
  200: '#ffb495',
  300: '#ff9769',
  400: '#ff8147',
  500: '#ff6e25',
  600: '#f46721',
  700: '#e6601c',
  800: '#d85919',
  900: '#c04c11',
};

const cyan = {
  50: '#e1f7fd',
  100: '#b5ebf8',
  200: '#84dff4',
  300: '#55d1ed',
  400: '#35c7e7',
  500: '#26bee0',
  600: '#21aecd',
  700: '#1999b2',
  800: '#138599',
  900: '#07626d',
};

const pink = {
  50: '#fce7ef',
  100: '#f8c2d9',
  200: '#f69cc0',
  300: '#f476a6',
  400: '#f25c92',
  500: '#f3467e',
  600: '#e14279',
  700: '#ca3d72',
  800: '#b4396d',
  900: '#8e3061'
};


const common = {
  black: '#000',
  white: '#fff'
}

const secondary = {
  main: grey[300],
}

const primary = {
  main: orange[600],
  light: orange[400],
  dark: orange[700],
  contrastText: common.white,
}

const success = {
  main: green[500],
}

const error = {
  main: red[600],
}

const warning = {
  main: yellow[500],
}

const info = {
  main: green[500],
}

const text = {
  primary: grey[600],
  secondary: grey[300],
  disabled: grey[100],
}

const divider = 'rgb(218,220,224)';

export {
  purple,
  green,
  // functional color
  common,
  secondary,
  primary,
  success,
  error,
  warning,
  info,
  text,
  divider,
  grey,
  orange,
  red,
  blue,
  coral,
  cyan,
  pink,
  yellow,
};
