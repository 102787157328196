import { createTheme, Theme } from "@mui/material/styles";
import {
  blue,
  common,
  coral,
  cyan,
  divider,
  error,
  green,
  grey,
  info,
  orange,
  pink,
  primary,
  purple,
  red,
  secondary,
  success,
  text,
  warning,
  yellow,
} from "./colors";

const theme: Theme = createTheme({
  configs: {
    menuWidth: 280,
    menuCollapsedWidth: 64,
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  palette: {
    mode: "light",
    background: {
      default: common.white,
    },
    divider,
    common,
    primary,
    secondary,
    success,
    error,
    warning,
    info,
    text,
    grey,
  },
  colors: {
    purple,
    green,
    orange,
    red,
    blue,
    coral,
    cyan,
    pink,
    yellow,
  },
  typography: (palette) => ({
    htmlFontSize: 16,
    fontFamily: "Poppins, sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
    h1: {
      fontWeight: 600
    },
    h2: {
      fontWeight: 600
    },
    h3: {
      fontWeight: 600
    },
    h4: {
      fontWeight: 600,
      fontSize: 24,
    },
    h5: {
      fontWeight: 600,
      fontSize: 20
    },
  }),
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&:last-child": {
            padding: theme.spacing(2),
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontWeight: 500
        }),
      },
    }
  },
});

export default theme;
