import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CallIcon from '@mui/icons-material/Call';
import { serviceCheckin, serviceCompleteUpload, serviceGetMe, serviceSendMessage, serviceUploadFile } from 'services/api/user';
import storageUtils from 'helpers/storageUtils';
import { useMe } from 'services/hooks/user';

function createChunks(file: File, cSize: number) {
    let startPointer = 0;
    const endPointer = file.size;
    const chunks = [];
    while (startPointer < endPointer) {
        const newStartPointer = startPointer + cSize;
        chunks.push(file.slice(startPointer, newStartPointer));
        startPointer = newStartPointer;
    }
    return chunks;
}

function convertCDN(url: string) {
    return url.replace('storage.googleapis.com', 'cdn-gcs.thuocsi.vn');
}

const ThankYou = () => {
    const { data, isLoading } = useMe();
    return (

        <div style={{ marginTop: "32px" }}>
            <div>
                <Stack direction="row" spacing={2}
                    alignContent="center"
                    justifyContent="center">
                    <Typography variant="h4">
                        Thank you for checking in, {data?.data[0].name}!
                    </Typography>
                </Stack >
            </div>
            <div style={{ marginTop: "20px" }}>
                <Stack direction="row" spacing={2}
                    alignContent="center"
                    justifyContent="center">
                    <Button style={{ width: "200px", color: "white" }} variant="contained" color="success"
                        startIcon={<CallIcon />}
                        onClick={() => {
                            window.location.href = '/companies';
                        }}>
                        Call someone
                    </Button>
                    <Button style={{ width: "200px", color: "white" }} variant="contained" color="primary"
                        startIcon={<TravelExploreIcon />}
                        onClick={() => {
                            window.location.href = '/companies';
                        }}>
                        Explore Orbit
                    </Button>
                </Stack>
            </div>
        </div>
    );
}

export default ThankYou;