import storageUtils from "helpers/storageUtils";
/**
 * Fetches data from the specified URL.
 *
 * @param endpoint The URL to fetch data from.
 * @param options Additional options for the fetch request.
 * @throws {Error} If an error occurs while fetching the data.
 * @returns {Promise<any>} A promise that resolves to the fetched data.
 */
export const fetchData = async (endpoint: string, options?: any): Promise<any> => {
  const apiUrl = process.env.REACT_APP_API_URL ?? '';
  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    Authorization: `Bearer ${storageUtils.getAccessToken()}`,
    ...(options?.headers ?? {}),
  };

  const fetchOptions = {
    ...(options ?? {}),
    method: options?.method ?? 'GET',
    headers
  };

  const url = `${apiUrl}${endpoint}`
  try {
    const response = await fetch(url, fetchOptions);
    const result = await response.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return newUnknownError('Failed to call endpoint: ' + url, error);
  }

  // const response = await fetch(`${apiUrl}${endpoint}`, fetchOptions);
  // if (!response.ok) {
  //   if (response.status === 401) {
  //     // maybe logout user here
  //   }
  //   const error: Error & { info?: any, status?: number } = new Error('Failed to fetch data');
  //   error.info = await response.json();
  //   error.status = response.status;
  //   throw error;
  // }

  // return response.json();
};
