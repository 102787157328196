const login = {
  login: "Login",
  email: "Email",
  emailRequired: "Email is required",
  emailFormat: "Email is invalid",
  password: "Password",
  passwordRequired: "Password is required",
  infoErrorMessage: "Incorrect email or password",
  logout: "Logout",
};

export default login;
