import common from 'locale/en/common';
import login from 'locale/en/login';
import menu from 'locale/en/menu';

const en = {
  common,
  login,
  menu
}

export default en;
