import React, { FC, useEffect, useState } from "react";
import i18n from "i18next";
import TranslateIcon from "@mui/icons-material/Translate";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import theme from "theme";
import { useTranslation } from "react-i18next";
import storageUtils from "helpers/storageUtils";

const languages = ["en", "vi"];

const LanguageSwitcher: FC<{ color?: string }> = ({ color }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    storageUtils.saveItem("language", language);
  }, [language]);

  return (
    <Box color={color ?? theme.palette.text.primary}>
      <IconButton
        size="large"
        color="inherit"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <TranslateIcon style={{ fontSize: 18 }} />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages.map((lng) => (
          <MenuItem
            key={lng}
            selected={language === lng}
            onClick={() => {
              switchLanguage(lng);
              handleClose();
            }}
          >
            {t(`common.${lng}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
