const menu = {
  listProjects: "Danh sách dự án",
  projectOverview: "Tổng quan",
  projectWorkingList: "Danh sách công việc",
  projectTimeline: "Timeline",
  projectReport: "Báo cáo",
  projectKanban: "Kanban",
  projectSites: "Sites",
  nftManagement: "Quản lý NFT",
  checkpointList: "Danh sách Checkpoint",
  nftList: "Danh sách NFT",
};

export default menu;
