import { API } from "services/enum/api";
import { fetchData } from "services/fetcher";

const serviceGetMe = async (): Promise<IResponseType<User>> => {
  console.log('serviceGetMe');
  return await fetchData(API.ME);
};

const serviceCheckin = async (data: any): Promise<IResponseType<Token>> => {
  return fetchData(API.CHECKIN, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

const serviceSendMessage = async (message: any): Promise<IResponseType<any>> => {
  return fetchData(API.MESSAGE_SEND, {
    method: 'POST',
    body: JSON.stringify(message),
  });
}

const serviceUploadFile = async (message: any): Promise<IResponseType<any>> => {
  return fetchData(API.UPLOAD, {
    method: 'POST',
    body: JSON.stringify(message),
  });
}
const serviceCompleteUpload = async (message: any): Promise<IResponseType<any>> => {
  return fetchData(API.UPLOAD, {
    method: 'PUT',
    body: JSON.stringify(message),
  });
}

export {
  serviceGetMe,
  serviceCheckin,
  serviceSendMessage,
  serviceUploadFile,
  serviceCompleteUpload,
};
