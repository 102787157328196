import { CssBaseline, Stack } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import defaultTheme from "theme";
import i18n from "configs/i18n"; // Import the i18n configuration

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function handleDragStart(e:any) {
  e.dataTransfer.setData("text/plain", "This is the draggable content");
}

function handleDragOver(e:any) {
  e.preventDefault(); // Necessary to allow dropping
}

root.render(
  <div
    draggable="true"
    onDragStart={handleDragStart}
    onDragOver={handleDragOver}
    style={{ cursor: 'grab' }} // Optional: Changes the cursor on hover to indicate draggability
  >
    <ReactErrorBoundary fallback={<ErrorBoundary />}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            ":root": { "--screen-height": `${window.innerHeight}px` },
            "#root": {
              position: "relative",
              minHeight: "var(--screen-height)",
            },
          }}
        />
        <Suspense
          fallback={
            <Stack height="var(--screen-height)" justifyContent="center">
              {/* <Loading /> */}
              {i18n.t("appLoading")}
            </Stack>
          }
        >
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Suspense>
      </ThemeProvider>
    </ReactErrorBoundary>
  </div>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
