import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from 'locale/en';
import viTranslation from 'locale/vi';
import storageUtils from "helpers/storageUtils";

const resources = {
  en: {
    translation: enTranslation,
  },
  vi: {
    translation: viTranslation,
  },
};

const defaultLanguage = storageUtils.getItem('language') ?? 'en';

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources,
    lng: defaultLanguage, // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
