export enum API {
  USER_GET_ONE = '/user/:id',
  LOGIN = '/api/admin/login',
  ME = '/api/me',

  COMPANY_GET_ALL = '/api/companies',
  COMPANY_GET_ONE = '/api/company',

  CHECKIN = '/api/checkin',
  MESSAGE_SEND = '/api/message',
  UPLOAD = '/api/upload',
};