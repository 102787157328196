import { Box, Button } from "@mui/material";
import Checkin from "components/Checkin/Checkin";
import Companies from "components/Companies";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { serviceGetAllCompanies } from "services/api/company";
import { useMe } from "services/hooks/user";
import { ICompany } from "services/types/company";

const Home = () => {
  const { isLoading } = useMe();
  const [isCheckInOpen, setIsCheckInOpen] = useState(false);
  const handleOpenCheckIn = () => setIsCheckInOpen(true);
  const handleCloseCheckIn = () => setIsCheckInOpen(false);

  const [companies, setCompanies] = useState<ICompany[]>([]);
  useEffect(() => {
    serviceGetAllCompanies().then((res) => {
      if (res.data) {
        setCompanies(res.data);
      }
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    // <div style={{ overflow: "hidden" }}>
    //   <Checkin open={isCheckInOpen} onClose={handleCloseCheckIn} />
    //   {!isCheckInOpen && <Button style={{ zIndex: "99999", position: "fixed", top: "1rem", right: "1rem" }} variant="contained" onClick={() => handleOpenCheckIn()}>Check-in</Button>}
    //   <Box position="relative" bottom={0} width="100%">
    //     <Companies data={companies} />
    //     <Outlet />
    //   </Box>
    // </div>

    <div style={{ overflow: "hidden" }}>
      <Box position="relative" bottom={0} width="100%">
        <Companies data={companies} />
        <Outlet />
      </Box>
    </div>
  );
};

export default Home;
