import { createContext, useCallback, useState } from 'react';
import {serviceGetAllCompanies} from '../services/api/company';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [companies, setCompanies] = useState([]);

  const getCompanies = useCallback(() => {
    if (companies.length > 0) {
      return companies;
    }
    
    serviceGetAllCompanies().then((res) => {
      if (res.data) {
        setCompanies(res.data);
        return res.data;
      } else {
        return [];
      }
    });
  }, [companies]);

    return (
        <AppContext.Provider value={{ companies, getCompanies }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;