import AppLayout from "components/Layouts";
import { ROUTES } from "helpers/routes";
import CheckinPage from "pages/checkin";
import CompanyDetails from "pages/company";
import FormPage from "pages/form";
import Home from "pages/home";
import Login from "pages/login";
import ThankYou from "pages/thank-you";
import { Route, Routes, Navigate } from "react-router-dom";

export const AppRoutes = () => (
  <Routes>
    <Route path={ROUTES.FORM} element={<FormPage />} />
    <Route path={ROUTES.CHECKIN} element={<CheckinPage/>} />
    <Route path={ROUTES.THANKYOU} element={<ThankYou/>} />
    <Route path={ROUTES.LOGIN} element={<Login />} />
    <Route path={ROUTES.HOME} element={<AppLayout />}>
      <Route index element={<Navigate to={ROUTES.CHECKIN} replace />} />
      <Route path={ROUTES.COMPANIES} element={<Home />}>
        <Route path={ROUTES.COMPANY_DETAILS} element={<CompanyDetails />} />
      </Route>
    </Route>
  </Routes>
);