import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { serviceCheckin, serviceCompleteUpload, serviceGetMe, serviceSendMessage, serviceUploadFile } from 'services/api/user';
import storageUtils from 'helpers/storageUtils';
import { useMe } from 'services/hooks/user';

function createChunks(file: File, cSize: number) {
    let startPointer = 0;
    const endPointer = file.size;
    const chunks = [];
    while (startPointer < endPointer) {
        const newStartPointer = startPointer + cSize;
        chunks.push(file.slice(startPointer, newStartPointer));
        startPointer = newStartPointer;
    }
    return chunks;
}

function convertCDN(url: string) {
    return url.replace('storage.googleapis.com', 'cdn-gcs.thuocsi.vn');
}

const CheckinPage = () => {
    const { data, isLoading } = useMe();
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        position: '',
        lookingFor: '',
        attachmentURLs: [],
    });
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (data && data.status == "OK") {
            window.location.href = '/thank-you';
        }
    }, [data]);
    
    // Handle input changes
    const handleChange = (e: any) => {
        const { name, value, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files ? files[0] : value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e: any) => {
        e.preventDefault();
        serviceCheckin({
            ...formData,
        }).then(response => {
            if (response?.status === "OK") {
                storageUtils.saveAccessToken(response.data[0].token)
                window.location.href = "/thank-you";
            }
        });
    };

    const handleChangeFile = (event: any) => {
        const file = event.target.files[0] as File;
        setUploading(true);
        serviceUploadFile({
            filename: file.name,
            type: file.type || "application/octet-stream",
            mime: file.type || "application/octet-stream",
            directory: "orbit_attachment",
        }).then(async (response) => {
            try {
                if (response.status != "OK") {
                    return Promise.resolve<IResponseType<any>>(response);
                }

                const data = response.data[0];
                const chunks = createChunks(file, 256 * 1024 * 1024);
                let cursor = 0;
                for (let i = 0; i < chunks.length; i++) {
                    const headers: any = {};
                    headers["Content-Type"] = file.type;
                    headers["Content-Range"] = `bytes ${cursor}-${cursor + chunks[i].size - 1
                        }/${file.size}`;

                    const res = await fetch(data.uploadLink, {
                        method: "PUT",
                        body: chunks[i],
                        headers,
                    });
                    if (res.status !== 308 && res.status != 200 && res.status != 201) {
                        return Promise.resolve<IResponseType<any>>({
                            status: res.statusText,
                            message: "Failed to upload file",
                        } as IResponseType<any>);
                    }
                    cursor += chunks[i].size;
                }

                const result = await serviceCompleteUpload({
                    uploadCode: data.uploadCode,
                })
                if (result.status !== "OK") {
                    return Promise.resolve<IResponseType<any>>(result);
                }
                setFormData((prevFormData: any) => {
                    prevFormData.attachmentURLs.push(result.data[0].link);
                    return {
                        ...prevFormData,
                    };
                });
            }
            catch (e: any) {

            }
            finally {
                setUploading(false);
            }
        });
    };

    const handleRemoveUrl = (indexToRemove: number) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            attachmentURLs: prevFormData.attachmentURLs.filter((_, index) => index !== indexToRemove),
        }));
    };

    const FileInput = useMemo(() => (
        <div>
            <input
                accept="*/*"
                style={{ display: 'none' }}
                id="attachments"
                multiple={false}
                type="file"
                onChange={handleChangeFile}
            />
            <label htmlFor="attachments">
                <Button variant="contained" component="span" disabled={uploading || formData.attachmentURLs.length >= 3}>
                    Choose file
                </Button>
            </label>
        </div>
    ), [formData, uploading]);

    if (isLoading) {
        return <div>Loading...</div>;
    // } else if (data?.status == "OK" && data?.data?.[0].name) {
    //     return (
    //         <div>
    //             <Typography variant="h4">
    //                 Thank you for checking in, {data.data[0].name}!
    //             </Typography>
    //         </div>
    //     );
    } else {
        return (
            <form onSubmit={handleSubmit}>
                <Box width="80%" maxWidth="500px" margin="auto">
                    <div style={{ paddingTop: "20px" }}>

                        <Stack spacing={4}>
                            <Stack direction="column" spacing={0}>
                                <Typography>
                                    Name:
                                </Typography>
                                <TextField
                                    label=""
                                    variant="standard"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Stack>

                            <Stack direction="column" spacing={0}>
                                <Typography>
                                    Company:
                                </Typography>
                                <TextField
                                    label=""
                                    variant="standard"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Stack>

                            <Stack direction="column" spacing={0}>
                                <Typography>
                                    Email:
                                </Typography>
                                <TextField
                                    label=""
                                    variant="standard"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Stack>

                            <Stack direction="column" spacing={0}>
                                <Typography>
                                    Position:
                                </Typography>
                                <TextField
                                    label=""
                                    variant="standard"
                                    name="position"
                                    value={formData.position}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Stack>

                            <Stack direction="column" spacing={0}>
                                <Typography>
                                    Looking for:
                                </Typography>
                                <TextField
                                    label=""
                                    variant="standard"
                                    name="lookingFor"
                                    value={formData.lookingFor}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Stack>

                            <Stack direction="column" spacing={0}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>
                                        Attachment:
                                    </Typography>
                                    {FileInput}
                                </Stack>

                                <div style={{ marginTop: "10px" }}>
                                    <Stack spacing={1}>
                                        {formData.attachmentURLs.map((url: string, index: number) => (
                                            <Stack direction="row" spacing={1} key={index} alignItems="center" sx={{ minWidth: 0, overflow: 'auto' }}>
                                                <IconButton size="small" color="error" onClick={() => handleRemoveUrl(index)} children={<ClearIcon />} />
                                                <Typography key={index} variant="body2" noWrap>
                                                    <a href={convertCDN(url)} target="_blank" rel="noopener noreferrer">
                                                        {url.split('/').pop()}
                                                    </a>
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </div>
                            </Stack>
                            <Button type="submit" color="success" variant="contained" fullWidth>
                                <Typography color="white">
                                    Checkin
                                </Typography>
                            </Button>
                        </Stack>
                    </div>
                </Box>
            </form>
        );
    }
}

export default CheckinPage;